import './App.css';
import React from 'react'
import ImageCarousel from './ImageCarousel'

function Products({ language }) {
  const translations = {
    CZ: {
      productsHead: "Najděte svou nejoblíbenější příchuť",
      productsText1: "Lázeňské oplatky jsou oblíbenou pochoutkou již po staletí. Pocházejí z 18. století ze slavných lázeňských měst západních Čech, kde je tradičně vychutnávali návštěvníci hledající léčivé účinky místních minerálních pramenů. Nyní přinášejí lázeňskou relaxaci lidem po celém světě.",
      productsText2: "Oplatky vyrábíme s různými náplněmi, abychom se každému trefili do chuti. Vyzkoušejte například lískooříškové oplatky, které jsou vyráběny z tradičních surovin, nebo třeba kokosové pro trochu exotiky. U nás si každý najde to své."
    },
    EN: {
      productsHead: "Find your favorite flavor",
      productsText1: "Spa wafers have been a popular delicacy for centuries. They originate from the 18th century in the famous spa towns of western Bohemia, where they were traditionally enjoyed by visitors seeking the healing effects of local mineral springs. Now they bring the relaxing spa experience to people around the world.",
      productsText2: "We make wafers with various fillings to suit everyone's taste. Try, for example, hazelnut wafers, which are made from traditional ingredients, or perhaps coconut wafers for a bit of exotic flavor. Everyone can find their favorite with us."
    }
  };

  return (
    <div id="vyrobky" className='products-div'>
      <div className="arrow-down"></div>
      <div className="products-head-div">
        <span className="products-head">{translations[language].productsHead}</span>
      </div>
      <ImageCarousel language={language} />
      <div className="products-text-div">
        <span className="products-text">{translations[language].productsText1}</span>
        <span>{translations[language].productsText2}
        </span>
      </div>
    </div>
  )
}

export default Products
