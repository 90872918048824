import './App.css'
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import prichut1 from './images/prichut1.jpeg'
import prichut2 from './images/prichut2.jpeg'
import prichut3 from './images/prichut3.jpeg'
import prichut4 from './images/prichut4.jpeg'
import prichut5 from './images/prichut5.jpeg'
import prichut6 from './images/prichut6.jpeg'

const ImageCarousel = ({ language }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isTinyScreen, setIsTinyScreen] = useState(false);
  const [slideDirection, setSlideDirection] = useState('');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);

  const nextSlide = () => {
    setSlideDirection('slide-left');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIsTransitioning(false);
    }, 300);
  };

  const prevSlide = () => {
    setSlideDirection('slide-right');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setIsTransitioning(false);
    }, 300);
  };

  const getSlideIndex = (index) => {
    return (index + images.length) % images.length;
  };

  const translations = {
    CZ: {
      images1: "Oplatky s čokoládovou náplní",
      images2: "Oplatky s kakaovou náplní",
      images3: "Oplatky s jablečnou náplní",
      images4: "Oplatky s kokosovou náplní",
      images5: "Oplatky s mandlovou náplní",
      images6: "Oplatky s lískooříškovou náplní",
    },
    EN: {
      images1: "Wafers with chocolate filling",
      images2: "Wafers with cocoa filling",
      images3: "Wafers with apple filling",
      images4: "Wafers with coconut filling",
      images5: "Wafers with almond filling",
      images6: "Wafers with hazelnut filling",
    }
  };
  const images = [
    { src: prichut1, alt: "Image 1", text: translations[language].images1 },
    { src: prichut2, alt: "Image 2", text: translations[language].images2 },
    { src: prichut3, alt: "Image 3", text: translations[language].images3 },
    { src: prichut4, alt: "Image 4", text: translations[language].images4 },
    { src: prichut5, alt: "Image 5", text: translations[language].images5 },
    { src: prichut6, alt: "Image 5", text: translations[language].images6 },
  ];

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1440);
      setIsTinyScreen(window.innerWidth < 600);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    let intervalId;
    if (isTinyScreen) {
      intervalId = setInterval(() => {
        nextSlide();
      }, 3000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isTinyScreen, currentIndex]);

  useEffect(() => {
    // Preload images
    images.forEach((image) => {
      const img = new Image();
      img.src = image.src;
      img.onload = () => {
        setLoadedImages((prev) => [...prev, image.src]);
      };
    });
  }, []);

  const renderImage = (src, alt, className) => {
    if (loadedImages.includes(src)) {
      return (
        <img
          src={src}
          alt={alt}
          className={className}
          style={{ opacity: 1, transition: 'opacity 0.3s ease-in-out' }}
        />
      );
    } else {
      return <div className={`image-placeholder ${className}`}></div>;
    }
  };

  const handleDotClick = (index) => {
    if (index === currentIndex) return;
    
    setSlideDirection(index > currentIndex ? 'slide-left' : 'slide-right');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsTransitioning(false);
    }, 300);
  };

  return (
    <div className="carousel-container">
      <div className={`carousel-images ${slideDirection}`}>
        { isSmallScreen ? (
          <div className={`carousel-image center ${isTransitioning ? 'transitioning' : ''}`}>
            {renderImage(images[currentIndex].src, images[currentIndex].alt, "round")}
          </div>
        ) : (
        [-1, 0, 1].map((offset) => {
          const index = getSlideIndex(currentIndex + offset);
          return (
            <div
              key={index}
              className={`carousel-image ${offset === 0 ? 'center' : 'adjacent'}`}
            >
              {renderImage(images[index].src, images[index].alt, offset === 0 ? "round" : "")}
            </div>
          );
        })
      )}
      </div>
      <div className="carousel-text-div">
        <p className="carousel-text">{images[currentIndex].text}</p>
      </div>

      {!isTinyScreen && (
        <>
          <button
            onClick={prevSlide}
            className="carousel-button prev"
          >
            <ChevronLeft size={48} strokeWidth={3} />
          </button>
          <button
            onClick={nextSlide}
            className="carousel-button next"
          >
            <ChevronRight size={48} strokeWidth={3} />
          </button>
        </>
      )}

      {isTinyScreen && (
        <div className="carousel-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
              onKeyPress={(e) => e.key === 'Enter' && handleDotClick(index)}
              role="button"
              tabIndex={0}
              aria-label={`Go to image ${index + 1}`}
            />
          ))}
        </div>
      )}


    </div>
  );
};

export default ImageCarousel;