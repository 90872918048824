import React from 'react'
import { Mail, Phone } from 'lucide-react';


function Contact({ language }) {
  return (
    <div id="kontakt" className="contact-div">
      <span className="contact-head">
        {language === 'CZ' 
        ? "Kontaktujte nás:"
        : "Contact us:"}
      </span>
      <div className="contact-items">
        <div className="contact-item">
          <Mail />
          <a href="mailto:info@morient.cz" className='contact-link'>
            info@morient.cz
          </a>
        </div>
        <div className='contact-item'>
          <Phone />
          <a href="tel:+420739052244" className='contact-link'>
            +420 739 052 244
          </a>
        </div>
        <div className='contact-item'>
          <Phone />
          <a href="tel:+420777709994" className='contact-link'>
            +420 777 709 994
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact
