import React, { useEffect } from 'react';
import Navigation from './Navigation';
import Hero from './Hero';
import Products from './Products'
import Contact from './Contact'
import { useState } from 'react'

const App = () => {
  const [language, setLanguage] = useState('CZ'); 
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };
  useEffect(() => {
    console.log(language)
  }, [language])

  return (<>
    <Navigation onLanguageChange={handleLanguageChange} />
    <Hero language={language} />
    <Products language={language} />
    <Contact language={language} />
    <div className="copyright-div">
      <span>© Morient s.r.o 2024</span>
    </div>
  </>);
};

export default App;