import './App.css';
import React, { useState, useRef, useEffect } from 'react'
import oplatka from './images/oplatka2.png'
import text from './images/text.png'
import textEN from './images/texten.png'
import textcentered from './images/textcentered.png'
import textENcentered from './images/textencentered.png'

const Hero = ({ language }) => {
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsVertical(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <div className="hero">
      <div className="hero-left">
        <img
          src={
            isVertical
              ? (language === 'CZ' ? textcentered : textENcentered)
              : (language === 'CZ' ? text : textEN)
          }
        />
      </div>
      <div className="hero-right">
        <img src={oplatka} />
      </div>
    </div>
  );
};

export default Hero;